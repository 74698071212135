import { StreamStatus } from 'Features/PhenixVideoPlayer/Enums/stream-status.enum';
import PhenixVideoPlayer from 'Features/PhenixVideoPlayer/PhenixVideoPlayer';
import { usePhenixWebRtcContext } from 'Features/PhenixVideoPlayer/PhenixWebRtcContext/PhenixWebRtcProvider';
import streamUnavailable from 'img/video_unavailable.jpg';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

import ImageGallery from '../Components/ImageGallery';
import classes from '../video-player-container.module.css';

interface IVideoDisplayView {
    refNum: string;
    showVideo: boolean;
    vehicleImages: any[];
    isMuted: boolean;
    setIsMuted: (muted: boolean) => void;
    videoUrl: string;
    children: ReactNode;
    isMobile?: boolean;
}

// eslint-disable-next-line max-lines-per-function
const VideoDisplayView: FC<IVideoDisplayView> = ({ refNum, showVideo, vehicleImages = [], isMuted, setIsMuted, videoUrl, children, isMobile }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const showDefaultImage = !showVideo && !vehicleImages.length;
    const [streamState, setStreamState] = useState<StreamStatus>(StreamStatus.NOT_PLAYING);
    const { removeMediaStream } = usePhenixWebRtcContext();
    let isComponentMounted = false;

    const handleIsEnded = () => {
        if (streamState !== StreamStatus.STREAM_ENDED) {
            removeMediaStream(videoUrl);
            if (isComponentMounted)
                setStreamState(StreamStatus.STREAM_ENDED);
        }
    };

    const handleHasError = (e: ErrorEvent | UIEvent) => {

        if (streamState !== StreamStatus.IS_ERRORED) {
            //removeMediaStream(videoUrl);
            if (isComponentMounted)
                setStreamState(StreamStatus.IS_ERRORED);
        }
    };

    const handleIsLoaded = () => {
        if (isComponentMounted) {
            setStreamState(StreamStatus.STREAM_IS_PLAYING);
        }

    };

    const handleChannelStatus = (e: Event | CustomEvent<StreamStatus>) => {
        const { detail } = e as CustomEvent<StreamStatus>;

        if (isComponentMounted && detail !== StreamStatus.STREAM_IS_PLAYING)
            setStreamState(detail);

        if (detail === StreamStatus.JOIN_SUCCESS) {
            return;
        } else if (detail === StreamStatus.NO_STREAM_PLAYING) {

        } else if (detail === StreamStatus.STREAM_ENDED) {
            removeMediaStream(videoUrl);
        } else if (detail === StreamStatus.STREAM_IS_PLAYING) {
        }

    };


    useEffect(() => {
        isComponentMounted = true;

        if (!videoRef.current)
            return;

        videoRef.current.addEventListener('channel_status', handleChannelStatus);
        videoRef.current.addEventListener('canplaythrough', handleIsLoaded);
        videoRef.current.addEventListener('ended', handleIsEnded);
        videoRef.current.addEventListener('error', handleHasError);
        videoRef.current.addEventListener('abort', handleHasError);

        return () => {
            isComponentMounted = false;
            videoRef.current?.removeEventListener('channel_status', handleChannelStatus);
            videoRef.current?.removeEventListener('canplaythrough', handleIsLoaded);
            videoRef.current?.removeEventListener('ended', handleIsEnded);
            videoRef.current?.removeEventListener('error', handleHasError);
            videoRef.current?.removeEventListener('abort', handleHasError);
        };
    }, [videoRef]);

    //useEffect(() => {
    //    if (streamState !== StreamStatus.STREAM_IS_PLAYING) return;

    //    let timer: NodeJS.Timeout;

    //    if ((isMobileAppleDevice && iosVersion >= 15) || isIpadVersion13Plus) { 
    //       // videoRef.current?.pause();
    //         videoRef.current!.style.display = 'none';  

    //        timer = setTimeout(() => {
    //            videoRef.current!.style.display = 'block';
    //            //if (videoRef.current?.paused) {
    //            //    videoRef.current!.style.display = 'block';
    //            //    videoRef.current
    //            //        .play()
    //            //        .then(() => {
    //            //            window.logger.log('vvvvvvvv222', streamState, videoRef.current?.paused);
    //            //            videoRef.current?.dispatchEvent(new CustomEvent('channel_status', { bubbles: true, detail: StreamStatus.STREAM_IS_PLAYING }));
    //            //        })
    //            //        .catch((err) => {
    //            //            window.logger.log('vvvvvvvv333', streamState, videoRef.current?.paused, err.name, err.message);
    //            //            //if (err.name !== 'NotAllowedError')
    //            //            //    videoRef.current?.dispatchEvent(new CustomEvent('channel_status', { bubbles: true, detail: StreamStatus.IS_ERRORED }))
    //            //            //else
    //            //            //    videoRef.current?.dispatchEvent(new CustomEvent('handle_play_trigger', { bubbles: true, detail: StreamStatus.NOT_ALLOWED_ERROR }))
    //            //        });
    //            //}
    //        }, 200);
    //    }

    //    return () => {
    //        if (timer) clearTimeout(timer);
    //    };
    //}, [streamState]);

    return (
        <React.Fragment>
            <div
                className={`image ${classes.image}`}
                style={{
                    minHeight: '150px'
                }}>
                {(showDefaultImage || (showVideo && streamState !== StreamStatus.STREAM_IS_PLAYING)) && <img src={streamUnavailable} />}
                {!showVideo && vehicleImages.length > 0 && <ImageGallery images={vehicleImages} />}
                <PhenixVideoPlayer
                    refNum={refNum}
                    show={showVideo && streamState === StreamStatus.STREAM_IS_PLAYING}
                    state={streamState}
                    videoRef={videoRef}
                    videoUrl={videoUrl}
                    isMuted={isMuted}
                    setIsMuted={setIsMuted}
                />
            </div>

            {children}
        </React.Fragment>
    );
};

export default VideoDisplayView;
