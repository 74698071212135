/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const isMobileAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
export const isOtherMobile = /Android|webOS|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
export const isAndroid = /Android/.test(navigator.userAgent);
export const isIpadVersion13Plus = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 4;
export const iosVersion =
    parseFloat(
        ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
            .replace('undefined', '3_2')
            .replace('_', '.')
            .replace('_', '')
    ) || 0;
